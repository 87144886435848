const header = {
  siteTitle: 'Peter R. Stuhlmann Fotografie',
  siteDescription: 'People- und Landschaftsfotografie aus Berlin',
  toggleButton: 'Seiten-Navigation öffnen',
  navigation: [
    {
      linkText: 'Home',
      href: '/',
    },
    {
      linkText: 'Portraits',
      href: '/portraits',
    },
    {
      linkText: 'Akt & Erotik',
      href: '/akt',
    },
    {
      linkText: 'Landschaften & Architektur',
      href: '/landschaftsfotografie',
    },
    {
      linkText: 'Über mich',
      href: '/ueber-mich',
    },
    // {
    //   linkText: 'Blog',
    //   href: '/blog',
    // },
    {
      linkText: 'Kontakt',
      href: '/kontakt',
    },
  ],
};

export default header;
