const footer = {
  navigation: {
    general: {
      heading: 'Allgemeines',
      list: [
        {
          linkText: 'Preise',
          path: '/preise',
        },
        // {
        //   linkText: 'Ablauf eines Shootings',
        //   path: '/ablauf',
        // },
        // {
        //   linkText: 'Häufige Fragen (FAQ)',
        //   path: '/faq',
        // },
        {
          linkText: 'Downloads',
          path: '/downloads',
        },
        {
          linkText: 'Sitemap',
          path: '/sitemap',
        },
      ],
    },
    languages: {
      heading: 'Sprache wählen',
      list: [
        {
          linkText: 'Deutsch',
          path: '/',
        },
        {
          linkText: 'English (not supported yet)',
          path: '/en',
        },
      ],
    },
    products: {
      heading: 'Produkte',
      list: [
        {
          linkText: 'Gutscheine',
          path: '/gutscheine',
        },
        {
          linkText: 'Wandkalender',
          path: '/kalender',
        },
        // {
        //   linkText: 'Prints (Fotoabzüge etc.)',
        //   path: '/prints',
        // },
        // {
        //   linkText: 'Partnerprogramm',
        //   path: '/partnerprogramm',
        // },
        {
          linkText: 'Foto API',
          path: '/api',
        },
      ],
    },
    legal: {
      heading: 'Rechtliches',
      list: [
        {
          linkText: 'Impressum',
          path: '/impressum',
        },
        {
          linkText: 'Datenschutzerklärung',
          path: '/datenschutzerklaerung',
        },
        // {
        //   linkText: 'AGB',
        //   path: '/agb',
        // },
        {
          linkText: 'Widerrufsbelehrung',
          path: '/widerrufsbelehrung',
        },
      ],
    },
    links: {
      heading: 'Links',
      list: [
        {
          linkText: 'Instagram: @peter.stuhlmann.fotografie',
          href: 'https://instagram.com/peter.stuhlmann.fotografie',
        },
        {
          linkText: 'peter-stuhlmann.de',
          href: 'https://peter-stuhlmann.de',
        },
        {
          linkText: 'Peter R. Stuhlmann Webentwicklung',
          href: 'https://peter-stuhlmann-webentwicklung.de',
        },
      ],
    },
  },
};

export default footer;
