const cookieConsent = {
  message:
    'Diese Webseite verwendet Cookies, um Ihnen ein angenehmeres Surfen zu ermöglichen. Weitere Infos: ',
  link: {
    text: 'Datenschutzerklärung',
    href: '/datenschutzerklaerung',
  },
  accept: 'Akzeptieren',
  decline: 'Ablehnen',
};

export default cookieConsent;
