const subFooter = {
  copyright: 'Peter R. Stuhlmann Fotografie',
  developer: {
    title: 'Designt und entwickelt von ',
    name: 'Peter R. Stuhlmann Webentwicklung',
    href: 'https://peter-stuhlmann-webentwicklung.de',
  },
};

export default subFooter;
